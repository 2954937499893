import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-equipmentpage',
  templateUrl: './equipmentpage.component.html',
  styleUrls: ['./equipmentpage.component.less']
})
export class EquipmentpageComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router
  ) {
    title.setTitle('FAQ | Auckland Waterjet Cutting');
    meta.addTag({ name: 'description', content: 'Read our answers to some of our frequently asked questions.'});
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

}
