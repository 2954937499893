<div class="dssf-page contact-page">
  <div class="row no-gutters contact-row">
    <div class="col-md-3 col-xl-3 contact-form pb-5">
      <div class="contact-shear">
        <p>Please fill out this form to contact us. Our aim is to reply to all enquiries within 2 days.</p>
        <form class="contact-page-form" #f="ngForm" (ngSubmit)="ContactSubmit(f.value, f.valid)" novalidate>
          <div class="form-group mb-4">
            <label>Name <span class="required">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/person-8x.png" height="14" /></div>
              </div>
              <input type="text" class="form-control" placeholder="Name" name="Name" [(ngModel)]="Contact.Name" #name="ngModel" required>
            </div>
            <div [hidden]="name.valid || (name.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Name is required.</i></small></div>
          </div>
          <div class="form-group mb-4">
            <label>Email <span class="required">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/envelope-closed-8x.png" height="14" /></div>
              </div>
              <input type="email" class="form-control" placeholder="Email" name="Email" [(ngModel)]="Contact.Email" #email="ngModel" required>
            </div>
            <div [hidden]="email.valid || (email.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Email is required.</i></small></div>
          </div>
          <div class="form-group mb-4">
            <label>Phone</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/phone-8x.png" height="14" /></div>
              </div>
              <input type="tel" class="form-control" placeholder="Phone" name="Phone" [(ngModel)]="Contact.Phone" #phone="ngModel">
            </div>
          </div>
          <div class="form-group mb-4">
            <label>Message <span class="required">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/text-8x.png" height="14" /></div>
              </div>
              <textarea class="form-control" rows="4" placeholder="Message" name="Message" maxlength="1000" [(ngModel)]="Contact.Message" #message="ngModel" required></textarea>
            </div>
            <div [hidden]="message.valid || (message.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Message is required.</i></small></div>
          </div>
          <button type="submit" class="btn btn-lg btn-custom">{{SubmitButtonText}}</button>
          <p class="mt-2" [ngClass]="{'text-success':SubmitSuccess, 'text-danger':!SubmitSuccess}">{{SubmitMessageResult}}</p>
        </form>
      </div>

    </div>
    <div class="col-md-5 col-xl-6 pb-3 contact-map">
      <div class="contact-image"></div>
      <!-- <div class="contact-shear">
      </div> -->
    </div>
    <div class="col-md-4 col-xl-3 contact-info">
      <div class="contact-shear">
        <div class="row justify-content-center">
          <div class="col-md-8 contact-details">
            <h3 class="mb-4">Contact Details</h3>
            <p><a href="tel:092667831" (click)="Track('phone-click','contact-page')"><img src="assets/phone-white.png" width="14" /> 09 266 78 31</a></p>
            <p><a href="tel:0274350926" (click)="Track('phone-click-mob','contact-page')"><img src="assets/phone-white.png" width="14" /> 027 435 09 26</a></p>
            <p><a href="mailto:estimator@awcutting.co.nz" (click)="Track('email-click','contact-page')"><img src="assets/email-white.png" width="14" /> estimator@awcutting.co.nz</a></p>
            <p>28 Westbrook Ave, Takanini, Auckland, 2105</p>
            <h3 class="mb-4">Opening Hours</h3>
            <p>Monday - Thursday 7:30am - 5pm</p>
            <p>Friday 7:30am - 4pm</p>
            <p>Saturday - by appointment</p>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>