import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ContactSubmission } from '../../types/ContactSubmission';
import { CustomResponse } from '../../types/CustomResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { UtilsService } from '../../services/utils.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';

@Component({
  selector: 'app-quotepage',
  templateUrl: './quotepage.component.html',
  styleUrls: ['./quotepage.component.less']
})
export class QuotepageComponent implements OnInit, AfterViewInit {

  public uploader: FileUploader = new FileUploader({
    url: 'https://utils.customwebsites.co.nz/v1/production/upload',
    maxFileSize: 5 * 1024 * 1024, // 5MB,
    disableMultipart: true,
    formatDataFunctionIsAsync: true,
    formatDataFunction: (fileItem) => {
      const encodeBase64 =  new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (event) => {
          const fileData = {
            file: fileReader.result,
            host: 'awcutting.co.nz',
            name: fileItem.file.name
          };
          resolve(fileData);
        };
        fileReader.readAsDataURL(fileItem.file.rawFile);
      });
      return encodeBase64;
    }
  });

  SubmitButtonText = 'Submit';
  SubmitMessageResult = '';
  SubmitSuccess = false;
  Contact: ContactSubmission = {
    Name: '',
    Email: '',
    Phone: '',
    Message: '',
    File: ''
  };
  FormInvalid = false;
  Uploading = false;
  UploadComplete = false;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
    private title: Title,
    private meta: Meta,
    private router: Router
  ) {
    title.setTitle('Free Quote | Auckland Waterjet Cutting');
    meta.addTag({ name: 'description', content: 'Each week we are working on a large range of waterjet cutting projects & have the capacity to assist you with your needs.'});
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    const options: FileUploaderOptions = {};
    options.headers = [{ name: 'x-api-key', value: 'zPXIyhZLXf8rAsl4Wc79g9xkp6JgO5yG7kSjLLqq' }];
    this.uploader.setOptions(options);
    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });
  }

  UploadFile(fileItem: any) {
    this.Uploading = true;
    this.uploader.uploadItem(fileItem);
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const result: any = JSON.parse(response);
      this.Contact.File = result.data.url;
      this.Uploading = false;
    };
  }

  FileSelected() {
    this.Uploading = true;
  }

  QuoteSubmit(contact: ContactSubmission, valid: boolean) {
    if (valid) {
      if (contact.Email.indexOf('@') === -1) {
        valid = false;
      }
    }
    if (valid) {
      this.FormInvalid = false;
      this.SubmitButtonText = 'Submitting...';
      this.api.SendContactRequest(this.Contact).subscribe(
        (response: CustomResponse) => {
          if (response.status === 'success') {
            this.SubmitMessageResult = 'Message delivered successfully';
            this.SubmitSuccess = true;
            this.utils.TrackSubmit('quote-page-form', 'success', 1);
          } else {
            this.SubmitMessageResult = 'Error delivering message';
          }
          this.SubmitButtonText = 'Submit';
          this.Contact = {
            Name: '',
            Email: '',
            Phone: '',
            Message: ''
          };
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client error: ', err.error.message);
            this.SubmitButtonText = 'Error delivering message';
          } else {
            console.log('Error code: ${err.status}, message: ${err.error}');
            this.SubmitButtonText = 'Error delivering message';
          }
          this.SubmitButtonText = 'Submit';
        }
      );
    } else {
      this.FormInvalid = true;
    }
  }

}
