import { Component, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ApiService } from './services/api.service';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  HeaderActive: boolean;
  Track = this.utils.TrackAction;
  scrollingDown;
  lastScrollTop = 0;
  CurrentHeaderLogo = 'assets/logo-white.png';

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset;
    if (scrollTop > this.lastScrollTop) {
      if (screen.width > 768) {
        if (!this.HeaderActive) {
          this.scrollingDown = true;
          this.CurrentHeaderLogo = 'assets/logo-black.png';
        }
      }
    } else {
      if (!this.HeaderActive) {
        this.scrollingDown = false;
        this.CurrentHeaderLogo = 'assets/logo-white.png';
      }
    }
    if (scrollTop === 0) {
      if (!this.HeaderActive) {
        this.scrollingDown = false;
        this.CurrentHeaderLogo = 'assets/logo-white.png';
      }
    }
    this.lastScrollTop = scrollTop;
  }

  constructor(
    private router: Router,
    private api: ApiService,
    private utils: UtilsService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const page: string = event.url.substring(1);
        if (event.url !== '/home') {
          this.HeaderActive = true;
          this.scrollingDown = true;
          this.CurrentHeaderLogo = 'assets/logo-black.png';
        } else {
          this.HeaderActive = false;
          this.scrollingDown = false;
        }
        this.utils.TrackPageView(page);
      }
    });
  }

  ngOnInit(): void {

  }

}
