import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ContactpageComponent } from './components/contactpage/contactpage.component';
import { EquipmentpageComponent } from './components/equipmentpage/equipmentpage.component';
import { ProjectspageComponent } from './components/projectspage/projectspage.component';
import { QuotepageComponent } from './components/quotepage/quotepage.component';
import { ProductspageComponent } from './components/productspage/productspage.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: 'faq',
    component: EquipmentpageComponent
  },
  {
    path: 'gallery',
    component: ProjectspageComponent
  },
  {
    path: 'quote',
    component: QuotepageComponent
  },
  {
    path: 'contact',
    component: ContactpageComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
