import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { Routes } from '@angular/router';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
// import { AgmCoreModule } from '@agm/core';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';

import { TimeSinceDirective } from './directives/time-since.directive';
import { ApiService } from './services/api.service';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ContactpageComponent } from './components/contactpage/contactpage.component';
import { UtilsService } from './services/utils.service';
import { EquipmentpageComponent } from './components/equipmentpage/equipmentpage.component';
import { QuotepageComponent } from './components/quotepage/quotepage.component';
import { ProjectspageComponent } from './components/projectspage/projectspage.component';
import { ProductspageComponent } from './components/productspage/productspage.component';

@NgModule({
  declarations: [
    AppComponent,
    TimeSinceDirective,
    HomepageComponent,
    ContactpageComponent,
    EquipmentpageComponent,
    QuotepageComponent,
    ProjectspageComponent,
    ProductspageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'customwebsite' }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    FileUploadModule,
    Angulartics2Module.forRoot([Angulartics2GoogleTagManager]),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAapv-PPbdnSXC9pIhYFZiu57JybrcIeCc'
    // }),
    // NgbModule.forRoot()
  ],
  providers: [
    ApiService,
    UtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

