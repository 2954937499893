import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-projectspage',
  templateUrl: './projectspage.component.html',
  styleUrls: ['./projectspage.component.less']
})
export class ProjectspageComponent implements OnInit {

  @ViewChild('otherwork') otherwork: ElementRef;
  @ViewChild('mildwork') mildwork: ElementRef;

  OtherProjects = [
    {
      URL: 'assets/projects/other/1.jpg',
      Title: 'Marine Exhaust',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/2.jpg',
      Title: 'Rooftop Furniture',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/3.jpg',
      Title: 'Plant Pipework',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/4.jpg',
      Title: 'Ice Bin',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/5.jpg',
      Title: 'Trophy & Stand',
      Type: 'Aluminium'
    },
    {
      URL: 'assets/projects/other/6.jpg',
      Title: 'Helix Mixer',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/7.jpg',
      Title: 'Trophy',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/8.jpg',
      Title: 'Vintage Car Component',
      Type: 'Stainless Steel (Brass)'
    },
    {
      URL: 'assets/projects/other/9.jpg',
      Title: 'Vet Cages',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/10.jpg',
      Title: 'Booth Seating',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/11.jpg',
      Title: 'Decorative Panel',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/12.jpg',
      Title: 'Watercut Design',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/13.jpg',
      Title: 'Spinner',
      Type: 'Mild Steel'
    },
    {
      URL: 'assets/projects/other/14.jpg',
      Title: 'Marine Exhaust',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/15.jpg',
      Title: 'Marine Exhaust',
      Type: 'Stainless Steel'
    },
    {
      URL: 'assets/projects/other/16.jpg',
      Title: 'Air Conditioning Vent',
      Type: 'Aluminium'
    },
    {
      URL: 'assets/projects/other/17.jpg',
      Title: 'Custom Piece',
      Type: 'Mild Steel'
    },
    {
      URL: 'assets/projects/other/18.jpg',
      Title: 'Custom Hard Top',
      Type: 'Aluminium'
    }
  ];

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private route: ActivatedRoute
  ) {
    title.setTitle('Gallery | Auckland Waterjet Cutting');
    meta.addTag({ name: 'description', content: 'Here is a selection of some of Waterjet cutting work.'});
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        setTimeout(() => {
          if (params.work === 'stainless') {
            this.otherwork.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else if (params.work === 'mild') {
            this.mildwork.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);

      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

}
