<div class="dssf-page home-page">

  <div class="home-intro">
    <div class="row">
      <div class="col home-section">
        <div class="row justify-content-center">
          <div class="col-md-8 pt-5 pb-5">
            <h1>Auckland Waterjet Cutting</h1>
            <h2>Custom design and full CAD drawing services. From idea to prototyping to DXF drawing ready for cutting. Repeat order efficiency.</h2>
          </div>
        </div>
        <a class="btn btn-lg btn-custom-outline home-section-button" routerLink="/quote">Get A Free Quote</a>
        
      </div>
    </div>
    <div class="home-section-overlay home-section-overlay-light"></div>
    <div class="home-video">
      <video autoplay loop muted playsinline poster="assets/video-wallpaper.jpg" oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="assets/wj.mp4" type="video/mp4">
      </video>
    </div>
  </div>

  <div class="row justify-content-center home-section-separator">
    <div class="col-md-8 pt-4 pb-4">
      <h3>We have state of the art waterjet cutting equipment which services many Auckland businesses</h3>
    </div>
  </div>  

  <div class="home-about">
    <div class="home-about-separator"></div>
    <div class="col-md-5 pt-5 pb-5 home-about-shear">
      <div class="row mb-4">
        <div class="col-4">
          <img src="assets/logo-black.png" class="img-fluid about-logo" alt="awc logo" />
        </div>
      </div>
      
      <p>Auckland Waterjet Cutting was formed as an addition to the family owned and operated engineering firm <a href="https://www.dssf.co.nz" target="_blank">Darryl Smith Stainless Fabrications</a> which is based in Takanini and has specialized in custom sheetmetal fabrication for over 30 years.</p>

      <p>With the recent installation of the state of the art Technijet abrasive waterjet cutter we have the ability to cut through almost any material with extreme accuracy allowing us to provide precision CNC cutting services to a wide range of industries.</p>
      
      <p><strong><span>&ldquo;</span>Our team are not only experienced but extremely passionate about what they do. We take pride in every project and product produced for our customers.<span>&rdquo;</span></strong></p>
          
      <p>In addition to our cutting services we employ an experienced team who provide numerous in-house value added services including design and CAD drawing. We also have the added advantage of working in conjunction with <a href="https://www.dssf.co.nz" target="_blank">Darryl Smith Stainless Fabrications</a> - team of fully qualified tradesmen providing full fabrication and engineering services where required.</p>
    </div>
  </div>

  <div class="row">
    <div class="col home-section-2">
      
      <div class="row mb-5 mt-5">
        <div class="col-md-4 offset-md-1 pt-5 pb-5">
          <h2 class="home-section-title mb-5">A Large Range of Materials</h2>
          <p class="home-section-subtitle">
            <strong>We Cut:</strong><br>
            Stainless steel, tool steel, aluminium, brass, copper, titanium, stone, wood, glass, rubber, ceramic tiles & more!
          </p>
          <p class="home-section-subtitle mb-5">
            <strong>We Stock:</strong><br>
            Stainless steel, Aluminium and mild steel in most thicknesses up to 25mm.
          </p>
          <a class="btn btn-lg btn-custom-outline home-section-button" routerLink="/gallery">View Gallery</a>
        </div>
      </div>
      
      <div class="home-section-overlay"></div>
    </div>
  </div>

  <div class="row justify-content-center text-left home-stateofart">
    <div class="col-md-10">
      <div class="row justify-content-between">
        <div class="col-md-5 pt-5 pb-5">
          <h2 class="home-section-title mb-5">What do we mean by "State of the Art" Equipment?</h2>
          <p class="home-section-subtitle">
            AWC’s premium Techni waterjet cutter provides precision CNC cutting at the highest accuracy of motion in the industry. The use of high pressure water mixed with abrasive eliminates heat allowing the cutting of almost any material without distortion or warping. This state of the art technology cuts smooth, precise edges in any two dimensional shape from DXF files. Ability to tightly nest parts helps eliminate as much wastage as possible. The final result is a satin-edged finish reducing secondary operations allowing a fast turnaround from concept to final product.
          </p>
          <a class="btn btn-lg btn-custom-outline home-section-button" routerLink="/quote">Get A Free Quote</a>
        </div>
        <div class="col-md-4">
          <div class="background-blue mt-5 pt-4 pb-4">
            <h2>Techni Waterjet TJ 4000-X3</h2>
            <h4><i>Specifications</i></h4>
            <ul class="mt-3">
              <li><p>Cutting table size:  1860 x 4000mm</p></li>
              <li><p>Max material thickness: 200mm</p></li>
              <li><p>Material Handling: up to 2.5 tonne</p></li>
              <li><p>Standard cutting accuracy: +/- 0.1mm</p></li>
              <li><p>Materials: Stainless Steel, Granite, Steel, Wood, Aluminium, Plastic, Brass, Tiles, Stone, Glass, Copper & Signboard</p></li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="home-section-overlay"></div>
    <div class="home-video">
      <video autoplay loop muted playsinline poster="assets/video-wallpaper.jpg" oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="assets/services3.webm" type="video/webm">
      </video>
    </div>
  </div>

  <div class="row justify-content-center home-section-separator">
    <div class="col-md-10 pt-4 pb-4">
      <h3>We work with businesses from a range of industries</h3>
    </div>
  </div>

  <div class="home-services">
    <div class="home-service-block home-service-block-1"></div>
    <div class="home-service-block home-service-block-2"></div>
    <div class="home-service-block home-service-block-3"></div>
    <div class="row justify-content-center">
      <div class="col-sm-10 pb-5">
        <div class="row">
          <div class="col-sm-3">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <img src="assets/food.png" class="img-fluid" alt="food and packaging" />
              </div>
            </div>
            <h3>Food & Packaging</h3>
            <p>We are fabricators of general sheet metal fabrication for food and industry stainless steel being our speciality. We can supply sink benches, wall claddings, enclosures, conveyors, transition pieces, stainless steel pipe work, chutes etc.<br>All can be fabricated to a food standard if required.</p>
          </div>
          <div class="col-sm-3">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <img src="assets/architecture.png" class="img-fluid" alt="architectural" />
              </div>
            </div>
            <h3>Architectural</h3>
            <p>We are fabricators of architectural metal work. Our service can cover from design to installation if required. Our range covers balustrading handrails, wind and privacy screeens, gates, canopies, wall linings, any unusual creations. We are familiar with fabrication using architectural mesh and patterned stainless steel sheet.</p>
          </div>
          <div class="col-sm-3">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <img src="assets/marine.png" class="img-fluid" alt="marine" />
              </div>
            </div>
            <h3>Marine</h3>
            <p>We are fabricators of marine components such as grab rails, tanks in alloy and stainless steel, fridge and freezer liners, bait boards, stem fittings and exhaust risers in special grade stainless steel. We also specialize in alloy fabrication including boat repairs, modifications and fittings.</p>
          </div>
          <div class="col-sm-3">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <img src="assets/blueprint.png" class="img-fluid" alt="engineering" />
              </div>
            </div>
            <h3>Engineering</h3>
            <p>We are fabricators of custom steel work for industry, plant maintenance and new plant builds such as frames, structural posts, beams, machinery guarding, platforms, safety railings, bollards, downpipe protectors, trolleys and storage racks.</p>
          </div>
        </div>
      </div>

    </div>
  </div> 

  <div class="home-contact">
    <div class="row justify-content-center">
      <div class="col-sm-6 col-xl-4 pt-4 mt-5 mb-5 border-top-red">
        <h3>Get In Touch</h3>
        <p>Fill out the form below for any general enquiries and we'll be in touch within one business day.</p>
        <form class="contact-page-form" #f="ngForm" (ngSubmit)="ContactSubmit(f.value, f.valid)" novalidate>
          <div class="form-group">
            <label>Name <span class="required">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/person-8x.png" height="14" /></div>
              </div>
              <input type="text" class="form-control" placeholder="Name" name="Name" [(ngModel)]="Contact.Name" #name="ngModel" required>
            </div>
            <div [hidden]="name.valid || (name.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Name is required.</i></small></div>
          </div>
          <div class="form-group">
            <label>Email <span class="required">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/envelope-closed-8x.png" height="14" /></div>
              </div>
              <input type="email" class="form-control" placeholder="Email" name="Email" [(ngModel)]="Contact.Email" #email="ngModel" required>
            </div>
            <div [hidden]="email.valid || (email.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Email is required.</i></small></div>
          </div>
          <div class="form-group">
            <label>Phone</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/phone-8x.png" height="14" /></div>
              </div>
              <input type="tel" class="form-control" placeholder="Phone" name="Phone" [(ngModel)]="Contact.Phone" #phone="ngModel">
            </div>
          </div>
          <div class="form-group">
            <label>Message <span class="required">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><img src="assets/text-8x.png" height="14" /></div>
              </div>
              <textarea class="form-control" rows="4" placeholder="Message" name="Message" maxlength="1000" [(ngModel)]="Contact.Message" #message="ngModel" required></textarea>
            </div>
            <div [hidden]="message.valid || (message.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Message is required.</i></small></div>
          </div>
          <button type="submit" class="btn btn-lg btn-custom">{{SubmitButtonText}}</button>
          <p class="mt-2" [ngClass]="{'text-success':SubmitSuccess, 'text-danger':!SubmitSuccess}">{{SubmitMessageResult}}</p>
        </form>
      </div>
    </div>
  </div>

</div>