<div class="dssf-page services-page">
  <div class="row services-page-header">
    <div class="services-page-intro">
      <h2>Frequently Asked Questions</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 pt-5 pb-5">
      <h3>What material can we cut?</h3>
      <p>Pretty much anything except for toughened glass. If it’s something we haven’t cut before we would normally do a test cut to make sure there won’t be any issues before proceeding with the actual job.</p>

      <h3 class="mt-5">What thickness can we cut?</h3>
      <p>We can cut up to approx. 150mm thick but this really depends on the material being cut and the desired cut finish. Contact to discuss specific requirements</p>

      <h3 class="mt-5">How does pricing work, do we have a per metre rate?</h3>
      <p>Pricing is job specific and goes on cutting time. Material type/thickness and design detail all play a role in determining the cut time for each job. The best way is for the client to send through an email with a drawing including over all dimensions, material type, thickness quantities and any other job specific details that will help with pricing.</p>

      <h3 class="mt-5">How big is the machine bed?</h3>
      <p>the machine bed is 1860  x 4000 but overall cutting area is approx. 1680 x 3800</p>

      <h3 class="mt-5">What file format is required for cutting?</h3>
      <p>The machine uses dxf files but we can convert to dxf from other formats if need be.</p>

      <h3 class="mt-5">Does the client need to supply a cut file?</h3>
      <p>No we can provide programming services to produce cut files from samples, sketches, pdf images and templates.</p>

      <h3 class="mt-5">Do we stock any materials?</h3>
      <p>We stock Mild steel, Aluminium and Stainless steel in most thickness’ up to about 25mm.</p>
    </div>
  </div>

  <div class="row background-red">
    <div class="col text-center">
      <h3>We are experienced in meeting custom requirements. Give us a call today for a preliminary discussion of your needs.</h3>
    </div>
  </div>

</div>