import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { ContactSubmission } from '../../types/ContactSubmission';
import { CustomResponse } from '../../types/CustomResponse';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2 } from 'angulartics2';
import { ApiService } from '../../services/api.service';
import { UtilsService } from '../../services/utils.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-contactpage',
  templateUrl: './contactpage.component.html',
  styleUrls: ['./contactpage.component.less']
})
export class ContactpageComponent implements OnInit {

  IsBrowser: boolean;

  SubmitButtonText = 'Submit';
  SubmitMessageResult = '';
  SubmitSuccess = false;
  Contact: ContactSubmission = {
    Name: '',
    Email: '',
    Phone: '',
    Message: ''
  };
  FormInvalid = false;

  MapLatitude = -37.033636;
  MapLongitude = 174.914145;
  MapMarkerLatitude = -37.033636;
  MapMarkerLongitude = 174.914145;
  //MapMarkerLabel = 'Label';
  MapMarkerIcon = 'assets/map-marker.png';
  MapZoom = 14;
  MapStyle = [{"featureType":"water","stylers":[{"saturation":43},{"lightness":-11},{"hue":"#0088ff"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"hue":"#ff0000"},{"saturation":-100},{"lightness":99}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"color":"#808080"},{"lightness":54}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ece2d9"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#ccdca1"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#767676"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"landscape.natural","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#b8cb93"}]},{"featureType":"poi.park","stylers":[{"visibility":"on"}]},{"featureType":"poi.sports_complex","stylers":[{"visibility":"on"}]},{"featureType":"poi.medical","stylers":[{"visibility":"on"}]},{"featureType":"poi.business","stylers":[{"visibility":"simplified"}]}];

  Track = this.utils.TrackAction;

  constructor(
    private http: HttpClient,
    private meta: Meta,
    private title: Title,
    private angulartics2: Angulartics2,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private api: ApiService,
    private utils: UtilsService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    title.setTitle('Contact Us | Auckland Waterjet Cutting | Quality Waterjet Cutting Services');
    meta.addTag({ name: 'description', content: 'Contact information and form. Our aim is to reply to all enquiries within 2 days.'});
    this.IsBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }


  ContactSubmit(contact: ContactSubmission, valid: boolean) {
    if (valid) {
      if (contact.Email.indexOf('@') === -1) {
        valid = false;
      }
    }
    if (valid) {
      this.FormInvalid = false;
      this.SubmitButtonText = 'Submitting...';
      this.api.SendContactRequest(contact).subscribe(
        (response: CustomResponse) => {
          if (response.status === 'success') {
            this.SubmitMessageResult = 'Message delivered successfully';
            this.SubmitSuccess = true;
            this.utils.TrackSubmit('contact-page-form', 'success', 1);
          } else {
            this.SubmitMessageResult = 'Error delivering message';
          }
          this.SubmitButtonText = 'Submit';
          this.Contact = {
            Name: '',
            Email: '',
            Phone: '',
            Message: ''
          };
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client error: ', err.error.message);
            this.SubmitButtonText = 'Error delivering message';
          } else {
            console.log('Error code: ${err.status}, message: ${err.error}');
            this.SubmitButtonText = 'Error delivering message';
          }
          this.SubmitButtonText = 'Submit';
        }
      );
    } else {
      this.FormInvalid = true;
    }
  }

}
