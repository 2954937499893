<div class="dssf-page projects-page">
  <div class="row projects-page-header">
    <div class="projects-page-intro">
      <h2>Gallery</h2>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-6 text-center pb-5 pt-5">
      <h2>Here is a selection of some of Waterjet cutting work</h2>
    </div>
  </div>

  <div class="row">
    <div class="other-project-image col-sm-2" *ngFor="let image of OtherProjects" [ngStyle]="{'background-image': 'url(' + image.URL + ')'}">
      <!-- <div class="other-project-text">
        <h4>{{image.Title}}</h4>
      </div>
      <h5 class="other-project-type">{{image.Type}}</h5> -->
    </div>
  </div>

  <div class="row justify-content-center background-red">
    <div class="col-md-6 pt-3 pb-3 text-center">
      <p class="project-divider-text">Whether it is big or small, we are here to meet your needs.</p>
      <p class="project-divider-text"><a class="btn btn-custom-outline" routerLink="/quote">Get A Quote</a></p>
    </div>
  </div>

</div>