<div class="dssf-page quote-page">
  <div class="row quote-page-header">
    <div class="quote-page-intro">
      <h2>Free Quote</h2>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-8 quote-form">
      <div class="row">
        <div class="col-md-6">
          <form class="quote-page-form" #f="ngForm" (ngSubmit)="QuoteSubmit(f.value, f.valid)" novalidate>
            <div class="form-group">
              <label>Name <span class="required">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><img src="assets/person-8x.png" height="14" /></div>
                </div>
                <input type="text" class="form-control" placeholder="Name" name="Name" [(ngModel)]="Contact.Name" #name="ngModel" required>
              </div>
              <div [hidden]="name.valid || (name.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Name is required.</i></small></div>
            </div>
            <div class="form-group">
              <label>Email <span class="required">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><img src="assets/envelope-closed-8x.png" height="14" /></div>
                </div>
                <input type="email" class="form-control" placeholder="Email" name="Email" [(ngModel)]="Contact.Email" #email="ngModel" required>
              </div>
              <div [hidden]="email.valid || (email.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Email is required.</i></small></div>
            </div>
            <div class="form-group">
              <label>Phone</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><img src="assets/phone-8x.png" height="14" /></div>
                </div>
                <input type="tel" class="form-control" placeholder="Phone" name="Phone" [(ngModel)]="Contact.Phone" #phone="ngModel">
              </div>
            </div>
            <div class="form-group">
              <label>Message <span class="required">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><img src="assets/text-8x.png" height="14" /></div>
                </div>
                <textarea class="form-control" rows="2" placeholder="Message" name="Message" maxlength="1000" [(ngModel)]="Contact.Message" #message="ngModel" required></textarea>
              </div>
              <div [hidden]="message.valid || (message.pristine && !f.submitted)"><small [ngClass]="{'text-danger':FormInvalid}"><i>Message is required.</i></small></div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><img src="assets/file-8x.png" height="14" /></div>
                </div>
                <input type="file" ng2FileSelect class="form-control" [uploader]="uploader" (onFileSelected)="FileSelected()">
                <!-- <input type="file" ng2FileSelect class="form-control"> -->
                <div class="input-group-append">
                  <div class="input-group-text">
                    <button type="button" class="btn btn-sm btn-success" (click)="UploadFile(uploader.queue[0])" [hidden]="uploader.queue[0]?.isReady ||
                       uploader.queue[0]?.isUploading || uploader.queue[0]?.isSuccess || !uploader.queue[0]?.file">Click to attach file</button>
                  </div>
                </div>
              </div>
              <div class="progress upload-progress mt-3" *ngIf="uploader.queue[0] && Uploading">
                <div class="progress-bar bg-success progress-bar-striped" role="progressbar" [ngStyle]="{'width': uploader.queue[0]?.progress + '%'}"></div>
              </div>
            </div>
            <p class="text-info" *ngIf="Uploading"><small>Please attach the selected file before submitting.</small></p>
            <button type="submit" class="btn btn-lg btn-custom" [disabled]="Uploading">{{SubmitButtonText}}</button>
            <p class="mt-2" [ngClass]="{'text-success':SubmitSuccess, 'text-danger':!SubmitSuccess}">{{SubmitMessageResult}}</p>
          </form>
        </div>
        <div class="col-md-5 pt-5 background-red quote-description">
          <p>Each week we are working on a large range of sheetmetal projects & have the capacity to assist you with your needs.</p>
          <p>&nbsp;</p>
          <p>Please fill out the form to the left and we will get back to your promptly with a detailed quote for further discussion.</p>
        </div>
      </div>

    </div>
  </div>

</div>