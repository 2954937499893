import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ContactSubmission } from '../../types/ContactSubmission';
import { UtilsService } from '../../services/utils.service';
import { CustomResponse } from '../../types/CustomResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.less']
})
export class HomepageComponent implements OnInit {

  // Images: Array<any> = ['assets/home1.jpg', 'assets/home2.jpg', 'assets/home3.jpg'];

  SubmitButtonText = 'Submit';
  SubmitMessageResult = '';
  SubmitSuccess = false;
  Contact: ContactSubmission = {
    Name: '',
    Email: '',
    Phone: '',
    Message: ''
  };
  FormInvalid = false;

  Track = this.utils.TrackAction;

  constructor(
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private meta: Meta,
    private title: Title,
    private utils: UtilsService,
    private api: ApiService,
    private router: Router
  ) {
    title.setTitle('Auckland Waterjet Cutting | Exceptional Quality Waterjet Cutting Services');
    meta.addTag({ name: 'description', content: 'Custom design and full CAD drawing services. From idea to prototyping to DXF drawing ready for cutting. Repeat order efficiency.'});
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  ContactSubmit(contact: ContactSubmission, valid: boolean) {
    if (valid) {
      if (contact.Email.indexOf('@') === -1) {
        valid = false;
      }
    }
    if (valid) {
      this.FormInvalid = false;
      this.SubmitButtonText = 'Submitting...';
      this.api.SendContactRequest(contact).subscribe(
        (response: CustomResponse) => {
          if (response.status === 'success') {
            this.SubmitMessageResult = 'Message delivered successfully';
            this.SubmitSuccess = true;
            this.utils.TrackSubmit('home-page-form', 'success', 1);
          } else {
            this.SubmitMessageResult = 'Error delivering message';
          }
          this.SubmitButtonText = 'Submit';
          this.Contact = {
            Name: '',
            Email: '',
            Phone: '',
            Message: ''
          };
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client error: ', err.error.message);
            this.SubmitButtonText = 'Error delivering message';
          } else {
            console.log('Error code: ${err.status}, message: ${err.error}');
            this.SubmitButtonText = 'Error delivering message';
          }
          this.SubmitButtonText = 'Submit';
        }
      );
    } else {
      this.FormInvalid = true;
    }
  }

}
