import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { ContactSubmission } from '../types/ContactSubmission';

@Injectable()
export class ApiService {

    constructor(
        private http: HttpClient,
    ) { }

    // SUPPORT
    SendContactRequest(supportRequest: ContactSubmission) {
        return this.http.post('https://utils.customwebsites.co.nz/v1/production/mail', JSON.stringify({
            formData: supportRequest,
            host: 'awcutting.co.nz'
        }), {
            headers: new HttpHeaders().set('X-Api-Key', 'bzG82O8eUh8SWlmQULHma4ZUenTAk76rx8BRY68a')
        });
    }


}
