<div class="container-fluid">
  <div class="row home-header align-items-center justify-content-between" [ngClass]="{'header-scroll': scrollingDown }">
    <div class="home-header-logo col-md-1">
      <a routerLink="/home"><img [src]="CurrentHeaderLogo" class="img-fluid" alt="auckland waterjet cutting logo" /></a>
    </div>
    <div class="col-md-7 home-header-photo">
      <div class="row text-center">
        <div class="col">
          <a routerLink="/home" routerLinkActive="home-header-nav-link-active" class="home-header-nav-link">Home</a>
        </div>
        <div class="col">
          <a routerLink="/gallery" routerLinkActive="home-header-nav-link-active" class="home-header-nav-link">Gallery</a>
        </div>
        <div class="col">
          <a routerLink="/faq" routerLinkActive="home-header-nav-link-active" class="home-header-nav-link">FAQ</a>
        </div>
        <div class="col">
          <a routerLink="/quote" routerLinkActive="home-header-nav-link-active" class="home-header-nav-link">Quote</a>
        </div>
        <div class="col">
          <a routerLink="/contact" routerLinkActive="home-header-nav-link-active" class="home-header-nav-link">Contact</a>
        </div>
      </div>
    </div>
    <div class="col-md-2 text-right pt-2 header-contact">
      <p class="mb-0"><a class="home-header-nav-link" href="tel:092667831" (click)="Track('phone-click', 'header')"><img src="assets/phone-white.png" height="14" /> 09 266 7831</a></p>
      <p><a class="home-header-nav-link" href="mailto:estimator@awcutting.co.nz" (click)="Track('email-click', 'header')"><img src="assets/email-white.png" height="14" /> estimator@awcutting.co.nz</a></p>
    </div>
  </div>
</div>


<div class="container-fluid">

  <router-outlet></router-outlet>

</div>

<footer class="footer">
  <div class="row">
    <div class="col-6 col-sm-2 offset-sm-2 pt-4">
      <h4>Pages</h4>
      <ul>
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/gallery">Gallery</a></li>
        <li><a routerLink="/faq">FAQ</a></li>
        <li><a routerLink="/quote">Quote</a></li>
        <li><a routerLink="/contact">Contact</a></li>
      </ul>
    </div>
    <div class="col-6 col-sm-3 offset-sm-1 pt-4">
      <h4>Contact Us</h4>
      <p><a href="tel:092667831" (click)="Track('phone-click','footer')"><img src="assets/phone-white.png" width="12" /> 09 266 7831</a></p>
      <p><a href="mailto:estimator@awcutting.co.nz" (click)="Track('email-click','footer')"><img src="assets/email-white.png" width="12" /> estimator@awcutting.co.nz</a></p>
      <p><img src="assets/map-marker-white.png" width="10" /> 28 Westbrook Ave, Takanini, Auckland, 2105</p>
    </div>
    <div class="col-sm-2 pt-4">
      <h4>Links</h4>
      <p><a href="https://www.dssf.co.nz" target="_blank">www.dssf.co.nz</a></p>
    </div>
  </div>
  <div class="row justify-content-center footer-credits">
    <div class="col-sm-8">
      <div class="row">
        <div class="col">
          <p><small>Copyright © Auckland Waterjet Cutting. All Rights Reserved.</small></p>
        </div>
        <div class="col text-right">
          <p><small>This is a <a href="https://www.customwebsites.co.nz" target="_blank"><strong>custom</strong>website</a></small></p>
        </div>
      </div>
    </div>
  </div>
</footer>
