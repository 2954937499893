import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

declare let gtag: any;

@Injectable()
export class UtilsService {

    private topNavUpdatedSource = new Subject<boolean>();
    topNavUpdated$ = this.topNavUpdatedSource.asObservable();

    IsBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        this.IsBrowser = isPlatformBrowser(platformId);
    }

    updateTopNav(shouldHide: boolean) {
        this.topNavUpdatedSource.next(shouldHide);
    }

    TrackAction(type: string, source: string) {
        gtag('event', 'track-action', {
            'event_category': source,
            'event_label': type,
            'event_action': 'track-action',
            'value': 1
        });
        return true;
    }
    TrackSubmit(source: string, label: string, value: number) {
        if (this.IsBrowser) {
            gtag('event', 'submit', {
                'event_category': source,
                'event_label': label,
                'event_action': 'submit',
                'value': value
            });
        }
        return true;
    }
    TrackPageView(page: string) {
        if (this.IsBrowser) {
            gtag('config', 'UA-65622815-68', {'page_path': '/' + page });
        }
        return true;
    }

}
